import React from 'react'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import scrollTo from 'gatsby-plugin-smoothscroll'

import classNames from 'classnames'
import { MdAlarm, MdAttachMoney } from 'react-icons/md'
import { AiFillSafetyCertificate } from 'react-icons/ai'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CustomChairDisplay from '../components/CustomChairDisplay'
import ResEmbeddedYoutube from '../components/ResEmbeddedYoutube'

export const pageQuery = graphql`
  query {
    magnaBanner: file(relativePath: { eq: "general/magna-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    magnaCompactLogo: file(relativePath: { eq: "logos/magna-compact-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 883) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jetLinerBanner: file(relativePath: { eq: "general/jet-liner-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timelineBanner: file(relativePath: { eq: "general/timeline-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1152) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    magazine: file(relativePath: { eq: "general/magazine.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    novoChair: file(relativePath: { eq: "novo-chair/novo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 183) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tergoChair: file(relativePath: { eq: "tergo-chair/tergo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 183) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    magnaChair: file(relativePath: { eq: "magna-chair/magna.png" }) {
      childImageSharp {
        fixed(width: 150, height: 183) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    vsportChair: file(relativePath: { eq: "vsport-chair/vsport.png" }) {
      childImageSharp {
        fixed(width: 150, height: 183) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    vipChair: file(relativePath: { eq: "vip-chair/vip.png" }) {
      childImageSharp {
        fixed(width: 150, height: 183) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    maniStool: file(relativePath: { eq: "mani-stool/manistool-cappuccino.png" }) {
      childImageSharp {
        fixed(width: 123, height: 180) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pediStool: file(relativePath: { eq: "pedi-stool/pedistool-cappuccino-diamond.png" }) {
      childImageSharp {
        fixed(width: 169, height: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    techChair: file(relativePath: { eq: "tech-chair/techchair-ivory-diamond.png" }) {
      childImageSharp {
        fixed(width: 184, height: 340) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    customerChair: file(relativePath: { eq: "customer-chair/customerchair-ivory.png" }) {
      childImageSharp {
        fixed(width: 222, height: 351) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const IndexPage = props => {
  const [activeChairIndex, setActiveChairIndex] = React.useState(0)
  const [isShown, setIsShown] = React.useState(false)

  const pedicureChairsList = [
    {
      chairImgLink: props.data.magnaChair.childImageSharp.fixed,
      index: 0,
      model: 'magna',
      price: '4,395',
      defaultChairColor: 'ivory',
      defaultBowlColor: 'rosegold'
    },
    {
      chairImgLink: props.data.vipChair.childImageSharp.fixed,
      index: 1,
      model: 'vip',
      price: '4,995',
      defaultChairColor: 'ivory',
      defaultBowlColor: 'pearl'
    },
    {
      chairImgLink: props.data.vsportChair.childImageSharp.fixed,
      index: 2,
      model: 'vsport',
      price: '3,395',
      defaultChairColor: 'fossilgrey',
      defaultBowlColor: 'pearl'
    },
    {
      chairImgLink: props.data.tergoChair.childImageSharp.fixed,
      index: 3,
      model: 'tergo',
      price: '3,395',
      defaultChairColor: 'burgundy',
      defaultBowlColor: 'mocha'
    },
    {
      chairImgLink: props.data.novoChair.childImageSharp.fixed,
      index: 4,
      model: 'novo',
      price: '3,195',
      defaultChairColor: 'cappuccino',
      defaultBowlColor: 'graphite'
    }
  ]

  return (
    <Layout>
      <SEO
        title="Home"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container-fluid px-0">
        <div className="container py-5">
          <div className="row">
            <div className="col py-4 text-center">
              <h1>PEDICURE INDUSTRY INNOVATOR</h1>
              <p className="d-block px-4 mb-4">
                The <strong>WORLD'S FIRST PATENTED PEDICURE SPA CHAIR MANUFACTURER</strong> invented
                <strong className="text-secondary"> 100% DISPOSABLE JET-LINER</strong>,
                <strong className="text-secondary"> INFINITY OVERFLOW</strong> and
                <strong className="text-secondary"> EASYDRAIN</strong>
              </p>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                className="action-button rounded-pill my-4"
                to="/about"
              >
                Learn More
              </AniLink>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Img fluid={props.data.magnaBanner.childImageSharp.fluid} />
            <div className="banner-overlay">
              <Img fluid={props.data.magnaCompactLogo.childImageSharp.fluid} />
              <div className="d-flex flex-column align-items-end">
                <AniLink
                  paintDrip
                  duration={1.62}
                  hex="#ffffff"
                  className="action-button text-center rounded-pill my-2"
                  to="/pedicure-spa-chairs/magna"
                >
                  Learn More
                </AniLink>
                <a href="tel:7148958865" className="action-button text-center rounded-pill">
                  Call for Estimate
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid my-4 bg-light pt-4 shadow-sm px-0">
        <div className="row">
          <div className="col">
            <h1 className="w-100 px-2 text-center mb-0">PEDICURE INDUSTRY EVOLUTION</h1>
            <p className="w-100 px-2 text-center">IMPROVING HYGIENE & SANITIZATION STANDARDS</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Img fluid={props.data.timelineBanner.childImageSharp.fluid} />
          </div>
        </div>
      </div>

      <div className="container-fluid my-4 bg-black pb-4 px-0 shadow-sm">
        <div className="row">
          <div className="col">
            <Img fluid={props.data.jetLinerBanner.childImageSharp.fluid} />
          </div>
        </div>
        <div className="row py-1">
          <div className="col d-flex flex-column align-items-center p-2 rounded shadow-lg mx-2 bg-secondary text-light">
            <MdAttachMoney size="3rem" />
            <h6 className="text-uppercase text-center pt-2">Increase Salon Income</h6>
          </div>
          <div className="col d-flex flex-column align-items-center p-2 rounded shadow-lg mx-2 bg-secondary text-light">
            <MdAlarm size="3rem" />
            <h6 className="text-uppercase text-center pt-2">save your time on cleaning</h6>
          </div>
          <div className="col d-flex flex-column align-items-center p-2 rounded shadow-lg mx-2 bg-secondary text-light">
            <AiFillSafetyCertificate size="3rem" />
            <h6 className="text-uppercase text-center pt-2">Peace of mind sanitation</h6>
          </div>
        </div>
      </div>

      <div className="container-fluid my-4 p-4 bg-light">
        <div className="row py-4">
          <div className="col-lg-8">
            <h1>SAFER FASTER CLEANER</h1>
            <p className="text-justify">
              In 2015,
              <a href="https://www.barbercosmo.ca.gov" target="_blank" rel="noopener noreferrer">
                the California Board of Barbering and Cosmetology
              </a>
              announced new legislation that California nail techs using disposable pedicure liners
              can skip the 10-minute disinfecting soak of their pedicure basin between clients.
              Enter <strong>Contégo Spa Designs Inc., Pedi-Spa Chair</strong>. This unique chair was
              designed with patented technology that includes a disposable foot spa system called
              <strong> Disposable Contégo Jet-Liner™</strong>.
            </p>
          </div>
          <div className="col-lg-4 py-4">
            <p className="mb-4">
              <strong>NEW</strong> Technology <strong>DISPOSABLE CONTÉGO JET-LINER™</strong>
            </p>
            <ul className="benefits">
              <li>
                <p className="ml-4"> 100% Disposed After Each Use</p>
              </li>
              <li>
                <p className="ml-4"> No Cross Contamination</p>
              </li>
              <li>
                <p className="ml-4"> Germs are Completely Disposed</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Img fluid={props.data.magazine.childImageSharp.fluid} />
          </div>
        </div>
      </div>

      <div className="container-fluid my-4 bg-black py-4">
        <div className="row py-4">
          <div className="col text-center text-light mb-4">
            <h1>ADVANCED & PATENTED TECHNOLOGIES</h1>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col">
            <ResEmbeddedYoutube
              url="https://www.youtube.com/watch?v=UgweYkPh1jU?rel=0"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light my-4 py-4 px-0">
        <div className="row py-4">
          <h1 className="w-100 text-center">PEDICURE SPA CHAIRS</h1>
        </div>
        <div className="scroll-nav bg-light d-flex justify-content-around my-4 py-4 px-2">
          {pedicureChairsList.map(pedicureChair => {
            return (
              <div key={pedicureChair.model}>
                <div
                  role="button"
                  tabIndex="0"
                  className={classNames('chair-selection-btn shadow-sm mr-2', {
                    active: pedicureChair.index === activeChairIndex
                  })}
                  onClick={() => {
                    setIsShown(true)
                    setActiveChairIndex(pedicureChair.index)
                    scrollTo('#chair-display')
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      setIsShown(true)
                      setActiveChairIndex(pedicureChair.index)
                      scrollTo('#chair-display')
                    }
                  }}
                >
                  <Img fixed={pedicureChair.chairImgLink} />
                  <h2 className="text-uppercase w-100 text-center">{pedicureChair.model}</h2>
                  <AniLink
                    paintDrip
                    duration={1.62}
                    hex="#ffffff"
                    className="action-button rounded-pill d-block my-4 mr-2"
                    to={`/pedicure-spa-chairs/${pedicureChair.model}`}
                  >
                    Learn more
                  </AniLink>
                </div>
              </div>
            )
          })}
        </div>
        <div className="container-fluid px-0" id="chair-display">
          {isShown &&
            pedicureChairsList.map(pedicureChair => {
              return (
                <div
                  key={pedicureChair.model}
                  className={classNames('pedicure-chair py-4', {
                    active: pedicureChair.index === activeChairIndex
                  })}
                >
                  <div>
                    <CustomChairDisplay
                      model={pedicureChair.model}
                      des={pedicureChair.des}
                      price={pedicureChair.price}
                      defaultChairColor={pedicureChair.defaultChairColor}
                      defaultBowlColor={pedicureChair.defaultBowlColor}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <div className="container-fluid py-5 bg-light">
        <div className="row">
          <h1 className="w-100 text-center pb-5">ACCESSORIES</h1>
        </div>
        <div className="row py-2 px-2">
          <div className="col-lg-3 py-4 d-flex flex-column align-items-center justify-content-end">
            <Img fixed={props.data.maniStool.childImageSharp.fixed} />
            <div className="d-flex flex-column justify-content-center py-4">
              <h6 className="text-secondary mt-2 w-100 text-center">Digital Coloring May Vary</h6>
              <h2 className="text-center m-0">Mani Stool</h2>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                className="action-button text-center rounded-pill mt-4"
                to="/accessories"
              >
                Customize Your Chair
              </AniLink>
            </div>
          </div>
          <div className="col-lg-3 py-4 d-flex flex-column align-items-center justify-content-end">
            <Img fixed={props.data.pediStool.childImageSharp.fixed} />
            <div className="d-flex flex-column justify-content-center py-4">
              <h6 className="text-secondary mt-2 w-100 text-center">Digital Coloring May Vary</h6>
              <h2 className="text-center m-0">Pedi Stool</h2>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                className="action-button text-center rounded-pill mt-4"
                to="/accessories"
              >
                Customize Your Chair
              </AniLink>
            </div>
          </div>
          <div className="col-lg-3 py-4 d-flex flex-column align-items-center justify-content-end">
            <Img fixed={props.data.techChair.childImageSharp.fixed} />
            <div className="d-flex flex-column justify-content-center py-4">
              <h6 className="text-secondary mt-2 w-100 text-center">Digital Coloring May Vary</h6>
              <h2 className="text-center m-0">Tech Chair</h2>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                className="action-button text-center rounded-pill mt-4"
                to="/accessories"
              >
                Customize Your Chair
              </AniLink>
            </div>
          </div>
          <div className="col-lg-3 py-4 d-flex flex-column align-items-center justify-content-end">
            <Img fixed={props.data.customerChair.childImageSharp.fixed} />
            <div className="d-flex flex-column justify-content-center py-4">
              <h6 className="text-secondary mt-2 w-100 text-center">Digital Coloring May Vary</h6>
              <h2 className="text-center m-0">Customer Chair</h2>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                className="action-button text-center rounded-pill mt-4"
                to="/accessories"
              >
                Customize Your Chair
              </AniLink>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
